var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-list-view',{attrs:{"server-side":"","hideCreate":"","app":"audit","model":"auditplanheader","api-url":"audit/audit-plan-header/","title":_vm.$t('menu.auditPlan'),"editTo":{ name: 'auditPlanEdit' },"headers":_vm.headers},on:{"edit":_vm.onEdit,"delete":_vm.onDelete},scopedSlots:_vm._u([{key:"prefix-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.openDialogAuditPlan}},[_vm._v(" "+_vm._s(_vm.$t('btn.create'))+" ")])]},proxy:true},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"1500px"},model:{value:(_vm.dialogAuditPlan),callback:function ($$v) {_vm.dialogAuditPlan=$$v},expression:"dialogAuditPlan"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-4"},[_c('v-toolbar',{attrs:{"dense":"","color":"primary white--text"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('menu.suggestionAuditPlan'))+" ")])],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"company","type":"select-server","label":_vm.$t('fields.selectCompany'),"binds":{
                  apiUrl:
                    'contact/contact/?contact_type=company&state=approved&active=true'
                }},model:{value:(_vm.formData.contact_id),callback:function ($$v) {_vm.$set(_vm.formData, "contact_id", $$v)},expression:"formData.contact_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('app-input',{attrs:{"name":"suggestType","type":"select","label":_vm.$t('fields.suggestType'),"binds":{
                  apiUrl: 'doc-template/document/?active=true'
                },"items":[
                  {
                    label: 'Suggest',
                    value: 'suggest'
                  },
                  {
                    label: 'Display All',
                    value: 'display_all'
                  },
                  {
                    label: 'Duplicate Audit Plan',
                    value: 'duplicate'
                  }
                ]},model:{value:(_vm.formData.suggest_type),callback:function ($$v) {_vm.$set(_vm.formData, "suggest_type", $$v)},expression:"formData.suggest_type"}})],1)],1),(
              _vm.formData.suggest_type !== null &&
              _vm.formData.suggest_type !== undefined
            )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('app-table',{attrs:{"server-side":"","app":"audit","model":"auditplanheader","headers":_vm.suggestionHeader,"serverItems":_vm.suggestionItemItems,"clientItems":_vm.suggestionItemItems,"loading":_vm.loading,"server-items-length":_vm.suggestionItemServerItemsLength},on:{"server":_vm.getSuggestion},model:{value:(_vm.selectedSuggest),callback:function ($$v) {_vm.selectedSuggest=$$v},expression:"selectedSuggest"}})],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeAuditPlan}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.createAuditPlan}},[_vm._v(" Create ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})
}
var staticRenderFns = []

export { render, staticRenderFns }